// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-sublte-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }
}

.bg-dark-subtle{
    background-color: rgba(var(--#{$prefix}dark-rgb),.25)!important;
}

.bg-light-subtle{
    background-color: rgba(var(--#{$prefix}light-rgb),.4)!important;
}